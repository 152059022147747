.section-info {
  position: relative;
  background-image: url("../images/bg-upcoming-deals.jpg");
  background-size: contain;
  background-repeat: repeat;
  background-position: left center;
  padding-top: 60px;
  padding-bottom: 60px;
  overflow: hidden;
  @media (min-width: 1024px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .info-inner {
    position: relative;
    z-index: 1;
    min-height: 432px;
    background: #000F47;
    box-shadow: 0 0 8px 6px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 24px 20px;
    @media (min-width: 1024px) {
      padding: 55px 60px 110px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      background-image: url("../images/deal-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center bottom;
    }
  }
  .img-1 {
    position: relative;
    z-index: 1;
    @media (min-width: 1024px) {
      position: absolute;
      top: 0;
      left: auto;
      right: 40px;
    }
  }
  .btn-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }
}

.deal-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  margin-top: 40px;
  padding: 32px;
  align-items: center;
  background: linear-gradient(0deg, rgba(1, 14, 64, 0.8), rgba(1, 14, 64, 0.8)),
  linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  border: 1px solid #FFFFFF33;
  box-shadow: 0 4px 20px 0 #FFFFFF40 inset;
  border-radius: 16px;
}
