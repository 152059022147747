.section-join-us {
  position: relative;
  min-height: 998px;
  z-index: 1;
  background-image: url("../images/bg-join-us.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
  padding-top: 75px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, #00092F 0%, rgba(0, 9, 47, 0) 100%);
    @media (min-width: 1200px) {
      height: 107px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: -5px;
    left: 0;
    right: 0;
    z-index: -1;
    background-image: url("../images/bg-join-us-2.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    @media (min-width: 1200px) {
      bottom: -10px;
    }
  }
  .moon {
    margin: 0 auto 40px;
  }
}

.step-item {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
  &:last-child {
    padding-top: 70px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 31px;
    display: block;
    width: 16px;
    height: 100%;
    background: rgba(255, 255, 255, 0.10);
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.20) inset;
  }
  .number {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 79px;
    width: 79px;
    height: 62px;
    background-image: url("../images/join-us-number.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    > span {
      font-size: 40px;
      line-height: 48px;
    }
  }
}
.step-item__content {
  p {
    margin-bottom: 27px;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  li {
    position: relative;
    margin-bottom: 0;
    &:first-child {
      margin-bottom: 24px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 3px;
      left: -32px;
      display: block;
      width: 20px;
      height: 20px;
      background-color: rgba(255, 255, 255, 0.10);
      border-radius: 20px;
      box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.20) inset;
    }
    span {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      &:first-child {
        color: #FFC331;
      }
    }
  }
}
