// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";


// 4. Include any optional Bootstrap components as you like
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";

@mixin bgGradient {
  background: linear-gradient(180deg, #F6C912 0%, #633502 100%);
}

html {
	overflow-x: hidden;
}

body {
  background: radial-gradient(88.81% 133.22% at 53.14% -23.4%, #001256 5.93%, #00092B 96.3%);
  font-family: 'SF Distant Galaxy', cursive;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #FFFFFF;
  overflow: hidden;
  margin: 0;
}

//@media (min-width: 1200px) {
//  .container-md {
//    max-width: 800px;
//  }
//  .container-lg {
//    max-width: 970px;
//  }
//  .container-xl, .container-sm, .container {
//    max-width: 1170px;
//  }
//}

a {
  transition: all 0.5s ease;
  text-decoration: none;
  color: #FFFFFF;
}

img, video {
  max-width: 100%;
}

.inline-block {
  display: inline-block !important;
  padding-left: 4px;
  padding-right: 4px;
}

.mxw-610 {
  max-width: 610px;
}

.mt-4 {
  margin-top: 32px !important;
}

.mt-5 {
  margin-top: 50px !important;
}

.mt-10 {
  margin-top: 100px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 8px !important;
}

.mb-2 {
  margin-bottom: 16px !important;
}

.mb-4 {
  margin-bottom: 20px !important;
}

.mb-5 {
  margin-bottom: 30px !important;
}

.mb-7 {
  margin-bottom: 50px !important;
}

.mb-10 {
  margin-bottom: 100px !important;
}

.mt-10 {
  margin-top: 100px !important;
}

.align-items-end {
  align-items: flex-end!important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-space-around {
  justify-content: space-around;
}

.px-4 {
  padding-right: 1.5rem!important;
  padding-left: 1.5rem!important;
}

.gx-5 {
  --bs-gutter-x: 3rem;
}

.mxw-800 {
  max-width: 800px !important;
}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }
  to {
    transform: scale(1.8, 1.8);
    opacity: 0;
  }
}

.circle-pulse {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  @include bgGradient;
  position: absolute;
  opacity: 0;
  z-index: -1;
  animation: scaleIn 3s infinite cubic-bezier(.36, .11, .89, .32);
}

.bg-primary {
  background: #09093E !important;
}

.border-radius-60 {
  border-radius: 60px !important;
}

.section-inner {
  padding: 50px 0;
  @media (min-width: 1024px) {
    padding: 100px 0;
  }
}

.mxw-880 {
  max-width: 880px !important;
}

.mxw-1120 {
  max-width: 1120px !important;
}

.block-center {
  margin-left: auto !important;
  margin-right: auto !important;
}
/* KEYFRAMES */

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
}

@keyframes spin3D {
  from {
    transform: rotate3d(.5,.5,.5, 360deg);
  }
  to{
    transform: rotate3d(0deg);
  }
}

@keyframes configure-clockwise {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes configure-xclockwise {
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-315deg);
  }
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .25;
    transform: scale(.75);
  }
}

.preloading-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: #000F47;
}

.preloading-inner {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.spinner-box {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

/* SPINNING CIRCLE */

.leo-border-1 {
  position: absolute;
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63,249,220);
  background: linear-gradient(0deg, rgba(63,249,220,0.1) 33%, rgba(63,249,220,1) 100%);
  animation: spin3D 1.8s linear 0s infinite;
}

.leo-core-1 {
  width: 100%;
  height: 100%;
  background-color: #37474faa;
  border-radius: 50%;
}

.leo-border-2 {
  position: absolute;
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(251, 91, 83);
  background: linear-gradient(0deg, rgba(251, 91, 83, 0.1) 33%, rgba(251, 91, 83, 1) 100%);
  animation: spin3D 2.2s linear 0s infinite;
}

.leo-core-2 {
  width: 100%;
  height: 100%;
  background-color: #1d2630aa;
  border-radius: 50%;
}

/* ALTERNATING ORBITS */

.circle-border {
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63,249,220);
  background: linear-gradient(0deg, rgba(63,249,220,0.1) 33%, rgba(63,249,220,1) 100%);
  animation: spin .8s linear 0s infinite;
}

.circle-core {
  width: 100%;
  height: 100%;
  background-color: #1d2630;
  border-radius: 50%;
}

/* X-ROTATING BOXES */

.configure-border-1 {
  width: 115px;
  height: 115px;
  padding: 3px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fb5b53;
  animation: configure-clockwise 3s ease-in-out 0s infinite alternate;
}

.configure-border-2 {
  width: 115px;
  height: 115px;
  padding: 3px;
  left: -115px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(63,249,220);
  transform: rotate(45deg);
  animation: configure-xclockwise 3s ease-in-out 0s infinite alternate;
}

.configure-core {
  width: 100%;
  height: 100%;
  background-color: #1d2630;
}

/* PULSE BUBBLES */

.pulse-container {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pulse-bubble {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #3ff9dc;
}

.pulse-bubble-1 {
  animation: pulse .4s ease 0s infinite alternate;
}
.pulse-bubble-2 {
  animation: pulse .4s ease .2s infinite alternate;
}
.pulse-bubble-3 {
  animation: pulse .4s ease .4s infinite alternate;
}

/* SOLAR SYSTEM */

.solar-system {
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orbit {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fafbfC;
  border-radius: 50%;
}

.earth-orbit {
  width: 165px;
  height: 165px;
  -webkit-animation: spin 12s linear 0s infinite;
}

.venus-orbit {
  width: 120px;
  height: 120px;
  -webkit-animation: spin 7.4s linear 0s infinite;
}

.mercury-orbit {
  width: 90px;
  height: 90px;
  -webkit-animation: spin 3s linear 0s infinite;
}

.planet {
  position: absolute;
  top: -5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #3ff9dc;
}

.sun {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: linear-gradient(90deg, #FF8278 0%, #FF8AD0 100%);
}

.leo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.blue-orbit {
  width: 165px;
  height: 165px;
  border: 1px solid #91daffa5;
  -webkit-animation: spin3D 3s linear .2s infinite;
}

.green-orbit {
  width: 120px;
  height: 120px;
  border: 1px solid #91ffbfa5;
  -webkit-animation: spin3D 2s linear 0s infinite;
}

.red-orbit {
  width: 90px;
  height: 90px;
  border: 1px solid #ffca91a5;
  -webkit-animation: spin3D 1s linear 0s infinite;
}

.white-orbit {
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  -webkit-animation: spin3D 10s linear 0s infinite;
}

.w1 {
  transform: rotate3D(1, 1, 1, 90deg);
}

.w2 {
  transform: rotate3D(1, 2, .5, 90deg);
}

.w3 {
  transform: rotate3D(.5, 1, 2, 90deg);
}

.three-quarter-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #fb5b53;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin .5s linear 0s infinite;
}

@import './global/colors';
@import './components/_button';
@import './components/_text';
@import './components/_header';
@import "./components/_section-cover";
@import "./components/_section-builder";
@import "./components/_section-deals";
@import './components/_section-about';
@import './components/_section-nfts';
@import './components/_section-join-us';
@import './components/_section-info';
@import './components/_section-subscribe';
@import './components/_footer';
