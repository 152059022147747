.section-deals {
  position: relative;
  background-image: url("../images/body-bg-2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  min-height: 100vh;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    display: block;
    background-image: url("../images/galaxy.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  h2 {
    margin-top: 30px;
  }
}
