.section-subscribe {
  display: flex;
  align-items: center;
  min-height: 412px;
  background-image: url("../images/body-bg-6.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  h2 {
    margin-bottom: 40px;
  }
}
