.section-about {
  position: relative;
  background-image: url("../images/bg-why-starklaunch.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  min-height: 120vh;
  overflow: hidden;
  z-index: 1;
  padding-top: 80px;
  @media (min-width: 1200px) {
    padding-top: 120px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    top: 20%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    display: block;
    background-image: url("../images/galaxy.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    @media (min-width: 1024px) {
      background-position: bottom center;
    }
  }
}
