.section-hero {
  position: relative;
  background-color: #09093E;
  background-image: url("../images/section-01.jpg");
  background-size: contain;
  background-repeat: repeat;
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 60px;
  @media (min-width: 1024px) {
    padding-top: 128px;
    padding-bottom: 75px;
  }
  .hero-inner {
    position: relative;
    min-height: 565px;
    background: #000F47;
    box-shadow: 0 0 8px 6px rgba(0, 0, 0, 0.25);
    padding: 24px 20px;
    @media (min-width: 1024px) {
      padding: 40px 44px;
    }
  }
  .img-1 {
    position: relative;
    z-index: 1;
    max-width: 100%;
    margin: 0 auto;
  }
  .img-2 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .img-1,
  .img-2 {
    @media (min-width: 1024px) {
      position: absolute;
      top: 0;
      left: auto;
      right: 0;
    }
  }
  h1 {
    @media (min-width: 1024px) {
      max-width: 510px;
    }
  }
  .btn-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }
}
