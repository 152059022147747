h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

.heading {
  font-weight: 400;
  &--xl {
    & {
      position: relative;
      z-index: 1;
      background-image: linear-gradient(45deg, #9D9FFF 0.86%, #D82DFF 98.85%),
      linear-gradient(0deg, #000000, #000000);
      background-size: 100%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }
    &, &:after {
      font-size: 40px;
      line-height: 60px;
      @media (min-width: 768px) {
        font-size: 60px;
        line-height: 80px;
      }
    }
    &:after {
      content: attr(data-title);
      position: absolute;
      color: rgba(255, 255, 255, 0.2);
      top: -5px;
      left: 5px;
      z-index: -1;
    }
  }
  &--lg {
    background-image: linear-gradient(45deg, #9D9FFF 0.86%, #D82DFF 98.85%),
    linear-gradient(0deg, #000000, #000000);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    &, &:after {
      position: relative;
      z-index: 1;
      font-size: 36px;
      line-height: 48px;
      @media (min-width: 768px) {
        font-size: 48px;
        line-height: 56px;
      }
    }
    &:after {
      content: attr(data-title);
      position: absolute;
      color: rgba(255, 255, 255, 0.2);
      top: -4px;
      left: 3px;
      z-index: -1;
    }
  }
  &--md {
    font-size: 32px;
    line-height: 40px;
  }
  &--sm {
    font-size: 14px;
    line-height: 20px;
  }
}

.heading-white {
  font-weight: 400;
  & {
    position: relative;
    z-index: 1;
  }
  &, &:after {
    font-size: 40px;
    line-height: 60px;
    @media (min-width: 768px) {
      font-size: 60px;
      line-height: 80px;
    }
  }
  &:after {
    content: attr(data-title);
    position: absolute;
    color: rgba(255, 255, 255, 0.2);
    top: -3px;
    left: 3px;
    z-index: -1;
  }
}

.fw-600 {
  font-weight: 600 !important;
}

.text-white {
  color: #fff;
}

.text-dark {
  color: #0C0C4F;
}

.text-gradient {
  font-family: 'SF Distant Galaxy', cursive;
  background: linear-gradient(45deg, #9D9FFF 0%, #D82DFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.text-gray {
  color: #A5A5A5;
}

.text-sm {
  font-size: 14px !important;
}

.text-lg {
  font-size: 18px !important;
}

.text-xl {
  font-size: 20px !important;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}
