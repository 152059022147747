.section-builder {
  position: relative;
  background-image: url("../images/bg-incubation.jpg");
  background-size: contain;
  background-repeat: repeat;
  padding-top: 60px;
  overflow: hidden;
  @media (min-width: 1024px) {
    padding-top: 80px;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 70px;
    background: linear-gradient(180deg, #00092F 0%, rgba(0, 9, 47, 0) 100%);
    @media (min-width: 1200px) {
      height: 119px;
    }
  }
}

.builder-item {
  position: relative;
  z-index: 1;
  max-width: 375px;
  margin: 0 auto 40px;
  padding: 28px;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    &:before {
      background-image: url("../images/incubation-item-hover.svg");
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    transition: all 500ms;
  }

  &:before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-image: url("../images/incubation-item.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  h3 {
    min-height: 64px;
    margin: 24px 0;
    font-size: 24px;
    line-height: 32px;
  }

  ul {
    margin-bottom: 5px;
    padding-left: 15px;
    min-height: 120px;
    max-height: 120px;
    overflow-y: auto;
  }
}
