.section-nfts {
  position: relative;
  background-image: url("../images/bg-nft.jpg");
  background-size: contain;
  background-repeat: repeat;
  background-position: center top;
}

.nft-item {
  max-width: 373px;
  margin: 0 auto 40px;
  padding: 0 0 45px;
  background-image: url("../images/nft-bg.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  text-align: center;
  .feature-content {
    padding: 10px 25px 0;
  }
  p {
    margin: 10px auto 0;
    max-width: 350px;
  }
}
