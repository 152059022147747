.navbar {
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  backdrop-filter: blur(3px);
  padding-top: .5rem;
  width: 100%;
  z-index: 998;
  will-change: transform;
  transition: transform .5s linear;

  &.headroom--not-top {
    background-color: $dark;
  }

  &.headroom--pinned {
    transform: translateY(0%);
  }

  &.headroom--unpinned {
    transform: translateY(-100%);
  }

  > .container {
    display: block;
    @media (min-width: 1024px) {
      display: flex;
      align-items: center;
      min-height: 3.25rem;
      width: 100%;
    }
  }
}

.navbar-brand .navbar-item {
  padding-left: 1rem;
  padding-right: 1rem;
  &:first-child {
    @media (min-width: 1024px) {
      padding-left: 0;
    }
  }
  &:last-child {
    @media (min-width: 1024px) {
      padding-right: 0;
    }
  }
  .logo {
    width: 200px;
    max-height: none;
    transition: transform .5s linear;
  }
}

.navbar-brand,
.navbar-tabs {
  align-items: center;
}

.navbar-burger {
  display: inline-block;
  color: #fff !important;
  @media (min-width: 1024px) {
    display: none;
  }
  svg {
    width: 20px;
  }
}

.navbar-nav {
  @media screen and (min-width: 576px) {
    display: flex;
    justify-content: space-around;
  }

  @media screen and (min-width: 1024px) {
    justify-content: end;
    margin-right: 0;
    width: 100%;
  }
}

.navbar-menu {
  display: none;
  transition: all .4s ease;
  &.is-active {
    display: block;
  }
  @media screen and (max-width: 1023px) {
    background: rgba(11, 11, 15, 0.7);
    backdrop-filter: blur(10px);
    padding: 10px 20px 30px;
  }
  @media screen and (min-width: 1024px) {
    flex-grow: 1;
    flex-shrink: 0;
    display: block;
  }

}

a.navbar-item,
.navbar-link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem 0.6rem;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: $text-white;
  cursor: pointer;
  z-index: 99;

  @media (min-width: 1200px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  &:focus,
  &:focus-within,
  &:hover,
  &.is-active {
    color: $primary;
  }
}

.headroom--unpinned:not(.headroom--top),
.headroom--pinned:not(.headroom--top) {
  .navbar-brand .navbar-item .logo {
    width: 80px;
  }
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  min-height: 3.25rem;
  @media (max-width: 1023.9px) {
    width: 100%;
  }
}

.button-wrapper {
  .button {
    margin-right: 1rem;
  }
}
